@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  font-family: "Poppins", sans-serif !important;
}

html body {
  margin: 0;
  padding: 0;
  background-color: #f5f7fc !important;
  scroll-behavior: smooth;
  height: 100% !important;
  position: relative !important;
  overflow-x: hidden !important;
  box-sizing: border-box !important;
  overscroll-behavior: none;
}

main {
  flex-grow: 1;
}
.slider div {
  position: relative;
}

@media (min-width: 900px) {
  .slick-dots li button::before {
    font-size: 18px !important;
  }
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Estilos para Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* .slick-list > div {
  background: transparent !important;
  width: auto !important;
  height: auto !important;
} */

/* Izquierda a derecha */

@keyframes zoomIn {
  0% {
    transform: scale(0.8); 
    opacity: 0; 
  }
  100% {
    transform: scale(1); 
    opacity: 1; 
  }
}

.zoom-in {
  animation: zoomIn 0.5s ease-out;
}


.slider-container {
  background-color: #ffffff;
}

.slick-slide {
  min-height: 250px;
  max-height:500px;
}

.slick-slide img {
  min-height: 250px;
  max-height:500px;
}